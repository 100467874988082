import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    itemCatalogueList: [],
    selectedItemId: null,
    selectedItem: null,
    isDetailView: false,
    isManageView: false
};

export const ItemCatalogueSlice = createSlice({
    name: 'item-catalogue',
    initialState,
    reducers: {
        setItemCatalogues: (state, action) => {
            state.itemCatalogueList = action.payload;
        },
        setSelectedItemCatalogue: (state, action) => {
            state.selectedItem = action.payload;
        },
        addOrUpdateItemCatalogue: (state, action) => {
            const index = state.itemCatalogueList.findIndex(i => i.id === action.payload.id);
            if (index >= 0) {
                state.itemCatalogueList[index] = action.payload;
            } else {
                state.itemCatalogueList.push(action.payload);
            }
            state.selectedItemId = action.payload.id;
        },
        changeSelectedItemId: (state, action) => {
            state.selectedItemId = action.payload;
        },
        changeDetailView: (state, action) => {
            state.isDetailView = action.payload;
        },
        changeManageView: (state, action) => {
            state.isManageView = action.payload;
        },
        setItemImageId: (state, action) => {
            state.selectedItem.imageDocumentId = action.payload.documentId;
        }
    }
});

export const { setItemCatalogues, setSelectedItemCatalogue, addOrUpdateItemCatalogue, changeSelectedItemId, changeDetailView, changeManageView, setItemImageId } = ItemCatalogueSlice.actions;

export const fetchItemCatalogues = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.items.get(criteria);
        dispatch(setItemCatalogues(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const saveItemCatalogue = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = data.id ? await client.items.put(data.id, data) : await client.items.post(data);
        dispatch(addOrUpdateItemCatalogue(response));
        dispatch(setSelectedItemCatalogue(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchItemCatalogue = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.items.getById(id);
        dispatch(setSelectedItemCatalogue(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const uploadItemImage = (instance, itemId, image) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const formData = new FormData();
        formData.append('file', image);

        const response = await client.items.uploadImage(itemId, formData);
        dispatch(setItemImageId(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchItemImage = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.items.getImage(id);
        return response.blob();
    } catch (error) {
        throw error;
    }
};

export default ItemCatalogueSlice.reducer;