import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    itemCategoryList: [],
    selectedItemCategory: null
};

export const ItemCategorySlice = createSlice({
    name: 'item-categories',
    initialState,
    reducers: {
        getItemCategories: (state, action) => {
            state.itemCategoryList = action.payload;
        },
        setSelectedItemCategory: (state, action) => {
            state.selectedItemCategory = action.payload;
        },
        addNewItemCategory: (state, action) => {
            state.itemCategoryList = [...state.itemCategoryList, action.payload];
        },
        updateExistsItemCategory: (state, action) => {
            var index = state.itemCategoryList.findIndex(i => i.id === action.payload.id);
            state.itemCategoryList[index] = {...action.payload};
        }
    }
});

export const { getItemCategories, setSelectedItemCategory, addNewItemCategory, updateExistsItemCategory } = ItemCategorySlice.actions;

export const fetchItemCategories = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.itemCategories.get(criteria);
        dispatch(getItemCategories(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchItemCategory = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.itemCategories.getById(id);
        dispatch(setSelectedItemCategory(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const addItemCategory = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.itemCategories.post(data);
        dispatch(addNewItemCategory(response));
        dispatch(setSelectedItemCategory(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const updateItemCategory = (instance, data) => async (dispatch) => {
    try{
        const client = await ApiClientConnector(instance);
        const response = await client.itemCategories.put(data.id, data);
        dispatch(updateExistsItemCategory(response));
        dispatch(setSelectedItemCategory(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default ItemCategorySlice.reducer;