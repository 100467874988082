import { createSlice } from "@reduxjs/toolkit";
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    productsList: [],
    selectedProductId: null,
    selectedProduct: null,
    isDetailView: false,
    isManageView: false,
    selectedProductDesignDetails: null,
    designDetails: [],
    selectedProductStatus: null,
};

export const ProductsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        getProducts: (state, action) => {
            state.productsList = action.payload;
        },
        addProduct: (state, action) => {
            state.productsList.push(action.payload);
            state.selectedProductId = action.payload.id;
        },
        updateProduct: (state, action) => {
            const index = state.productsList.findIndex(i => i.id === action.payload.id);
            state.productsList[index] = action.payload;
        },
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
            if(action.payload != null) {
                state.designDetails = action.payload?.designDetails ?? [];
            }
        },
        setSelectedProductDesignDetail: (state, action) => {
            state.selectedProductDesignDetails = action.payload;
            if (state.selectedProduct != null) {
                state.selectedProduct.designDetails = action.payload;
            }
        },
        changeSelectedProductId: (state, action) => {
            state.selectedProductId = action.payload;
        },
        setIsViewProducts: (state, action) => {
            state.isDetailView = action.payload;
        },
        setIsManageProducts: (state, action) => {
            state.isManageView = action.payload;
        },
        setSelectedProductId: (state, action) => {
            state.selectedProductId = action.payload;
        },
        resetProductState: (state) => {
            state.selectedProductId = null;
            state.selectedProduct = null;
        },
        setSelectedProductStatus: (state, action) =>{
            state.selectedProductStatus = action.payload;
        }
    }
});

export const { getProducts, addProduct, updateProduct, setSelectedProduct, changeSelectedProductId, setIsManageProducts, setIsViewProducts, setSelectedProductId, resetProductState, setSelectedProductDesignDetail, setSelectedProductStatus } = ProductsSlice.actions;

export const fetchProducts = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.get(criteria);
        dispatch(getProducts(response));
        return response;
    } catch (error) {
        throw error;
    }
};

export const addNewProduct = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.post(data);
        dispatch(addProduct(response));
        dispatch(setSelectedProductStatus(response.status));
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateExistingProduct = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.put(data.id, data);
        dispatch(updateProduct(response));
        return response.id;
    } catch (error) {
        throw error;
    }
};

export const fetchProduct = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.getById(id);
        dispatch(setSelectedProduct(response));
        dispatch(setSelectedProductStatus(response.status));
        dispatch(changeSelectedProductId(id));
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateExistingProductDesign = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.putDesignDetail(data.id, data)
        dispatch(setSelectedProductDesignDetail(response));
        return response;
    } catch (error) {
        throw error;
    }
}

export const fetchProductDesign = (instance, id) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.getDesignDetail(id);
        dispatch(setSelectedProductDesignDetail(response));
        return response;
    } catch (error) {
        throw error;
    }
}

export const productApproval = (instance, id, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.products.putApprove(id, data);
        dispatch(setSelectedProductStatus(response.status));
        return response;
    } catch (error) {
        throw error;
    }
}

export default ProductsSlice.reducer;