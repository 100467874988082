import { createSlice } from "@reduxjs/toolkit"
import ApiClientConnector from "src/api/ApiClientConnector";

const initialState = {
    bomList: [],
    selectedBom: null
}

export const BomSlice = createSlice({
    name: "bill-of-materials",
    initialState,
    reducers: {
        getBoms: (state, action) => {
            state.bomList = action.payload;
        },
        addOrUpdateBom: (state, action) => {
            const index = state.bomList.findIndex(i => i.id === action.payload.id);
            if (index >= 0) {
                state.bomList[index] = action.payload;
            } else {
                state.bomList.push(action.payload);
            }
        },
        setSelectedBom: (state, action) => {
            state.selectedBom = action.payload;
        },
    }
})

export const { getBoms, addOrUpdateBom, setSelectedBom } = BomSlice.actions;

export const fetchBoms = (instance, criteria) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = await client.bom.get(criteria);
        dispatch(getBoms(response));
        return response;
    } catch (error){
        throw error;
    }
}

export const saveBom = (instance, data) => async (dispatch) => {
    try {
        const client = await ApiClientConnector(instance);
        const response = data.id ? await client.bom.put(data.id, data) : await client.bom.post(data);
        dispatch(addOrUpdateBom(response));
        return response;
    } catch (error){
        throw error;
    }
}

export default BomSlice.reducer;