import { DateTime } from "luxon";

export const convertToKeyValueList = (dataArray, key, value = null) => {
    return dataArray.reduce((acc, current) => {
        acc[current[key]] = value ? current[value] : current;
        return acc;
    }, {});
}

export const formatNumber = (value) => {
    return (new Intl.NumberFormat().format(value))
}

export const avoidMinusNumber = (value) => {
    return value > 0 ? Number(value) : 0;
}

export const isNullOrEmpty = (value) => {
    return value === undefined || value === null || value === "" || value?.toString().trim() === "";
}

export const formatLocalTime = (value) => {
    const utcDate = DateTime.fromISO(value, { zone: 'utc' });
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcDate.setZone(userTimeZone);
    return localDate.toFormat('yyyy-MM-dd HH:mm:ss a');
}

export const labelFormatType = (option, label, otherLabels, type) => {
    switch (type) {
        case 1:
            return [option[label], ` (${otherLabels.slice(0, 1).map(label => option[label] || "-")})`, ` - ${otherLabels.slice(1).map(label => option[label] || "N/A").join(" | ")}`].join("");
        default:
            return [option[label], ...otherLabels.map(label => option[label] ? option[label] : "-")].join(" | ");
    }
}

export const changeChipColour = (value) => ({
    'Active': 'primary',
    'Cancelled': 'error',
    'Completed': 'success',
    'Created': 'primary',
    'Approved': 'success',
    'Pending Approval': 'primary',
    'Rejected': 'error',
    'Allocated': 'info',
    'In Production': 'primary',
    'Post Production': 'secondary',
    'Shipped': 'info',
    'Drafted': 'warning',
    'Not Collected': 'warning',
    'Collected': 'success',
    'Waiting for designer': 'primary',
    'Waiting for reviewer': 'secondary',
    'Reviewer approved': 'success'
}[value])